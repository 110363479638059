import React from "react";
import { Field } from "formik";
import { MyHiddenField, NumberInput } from "./FormikElements";

import vsbig from "../../../assets/images/vs-big.png";
import vs from "../../../assets/images/vs.png";
import vs1 from "../../../assets/images/vs1.png";
import vs2 from "../../../assets/images/vs2.png";

const Step3 = (props) => {
  const {
    filters,
    distance,
    fuelLiters,
    fuelSum,
    gasLiters,
    gasSum,
  } = props.parentState.values;

  return (
    <>
      <div className="form-group">
        <Field
          label={"Distance"}
          component={NumberInput}
          name={"distance"}
          placeholder={"Distance ..."}
          className={"form-control"}
        />
      </div>

      {distance !== "" &&
        filters.includes("Бензин") &&
        !filters.includes("Газ") && (
          <div className="calc-sum">
            <h2>Total:</h2>
            <div className="calculator__oils-total">
              <p className="calculator__oils">
                The required amount of gasoline:{" "}
                <span>
                  <b>{fuelLiters} l.</b>
                </span>
              </p>{" "}
              <p className="calculator__oils">
                The cost of travel:{" "}
                <span>
                  <b>{fuelSum} $.</b>
                </span>
              </p>{" "}
              <p className="calculator__oils"></p>
            </div>
          </div>
        )}

      {distance !== "" &&
        filters.includes("Газ") &&
        !filters.includes("Бензин") && (
          <div className="calc-sum">
            <h2>Total:</h2>
            <div className="calculator__oils-total">
              <p className="calculator__oils">
                Required amount of gas:{" "}
                <span>
                  <b>{gasLiters} l.</b>
                </span>
              </p>{" "}
              <p className="calculator__oils">
                The cost of travel:{" "}
                <span>
                  <b>{gasSum} $.</b>
                </span>
              </p>{" "}
              <p className="calculator__oils"></p>
            </div>
          </div>
        )}

      {distance !== "" &&
        filters.includes("Бензин") &&
        filters.includes("Газ") && (
          <div className="calc-sum">
            <h2>Total:</h2>
            <div className="calculator__oils-total">
              <table className="table calculator__oils">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Petrol</th>
                    <th>
                      <img src={vsbig} alt={"vs"} className="icon-tb" />
                    </th>
                    <th scope="col">Gas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Required amount of gasoline:</th>
                    {fuelLiters === gasLiters && (
                      <>
                        <td>{fuelLiters} l.</td>
                        <td>
                          <img src={vs} alt={"vs"} className="icon-tb" />
                        </td>
                        <td>{gasLiters} l.</td>
                      </>
                    )}
                    {fuelLiters > gasLiters && (
                      <>
                        <td>{fuelLiters} l.</td>
                        <td>
                          <img src={vs1} alt={"vs1"} className="icon-tb" />
                        </td>
                        <td>
                          <span>{gasLiters} l.</span>
                        </td>
                      </>
                    )}
                    {fuelLiters < gasLiters && (
                      <>
                        <td>
                          <span>{fuelLiters} l.</span>
                        </td>
                        <td>
                          <img src={vs2} alt={"vs2"} className="icon-tb" />
                        </td>
                        <td>{gasLiters} l.</td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <th scope="row">The cost of travel:</th>
                    {Number(fuelSum) === Number(gasSum) && (
                      <>
                        <td>{fuelSum} $.</td>
                        <td>
                          <img src={vs} alt={"vs"} className="icon-tb" />
                        </td>
                        <td>{gasSum} $.</td>
                      </>
                    )}
                    {Number(fuelSum) > Number(gasSum) && (
                      <>
                        <td>{fuelSum} $.</td>
                        <td>
                          <img src={vs1} alt={"vs1"} className="icon-tb" />
                        </td>
                        <td>
                          <span>{gasSum} $.</span>
                        </td>
                      </>
                    )}
                    {Number(fuelSum) < Number(gasSum) && (
                      <>
                        <td>
                          <span>{fuelSum} $.</span>
                        </td>
                        <td>
                          <img src={vs2} alt={"vs2"} className="icon-tb" />
                        </td>
                        <td>{gasSum} $.</td>
                      </>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="calculator__oils-total text_block">
              <img src={vs} alt={"vs"} className="icon-tb" />
              <p className="calculator__oils">
                Savings in{" "}
                <span>
                  <b>{Math.abs(Number(fuelSum) - Number(gasSum))} $.</b>
                </span>
                <br />
                  It is more profitable to use{" "}
                <span>
                  <b>
                    {Number(fuelSum) - Number(gasSum) > 0 ? "Газ" : "Бензин"}
                  </b>
                </span>
                .
              </p>
            </div>
          </div>
        )}

      {distance !== "" && filters.includes("Газ/Бензин") && (
        <div className="calc-sum">
          <h2>Total:</h2>
          <div className="calculator__oils-total">
            <p className="calculator__oils">
              Required amount of gas:{" "}
              <span>
                <b>{gasLiters} l.</b>
              </span>{" "}
              / the cost:{" "}
              <span>
                <b>{gasSum} $.</b>
              </span>
            </p>
            <p className="calculator__oils">
              The required amount of gasoline:{" "}
              <span>
                <b>{fuelLiters} l.</b>
              </span>{" "}
              / the cost:{" "}
              <span>
                <b>{fuelSum} $.</b>
              </span>
            </p>
            <p className="calculator__oils">
              The cost of travel:{" "}
              <span>
                <b>{Number(fuelSum) + Number(gasSum)} $.</b>
              </span>
            </p>{" "}
            <p className="calculator__oils"></p>
          </div>
        </div>
      )}
      <MyHiddenField name="fuelLiters" className={"form-control hide"} />
      <MyHiddenField name="gasLiters" className={"form-control hide"} />
    </>
  );
};

export default Step3;
