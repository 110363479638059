import { createReducer } from "redux-act";
import * as courseActions from "./course.actions";

// получаем из хелпер ф-ции
const initialState = {
  course: {
    "UA" : "1",
    "RUB" : "1",
    "USD" : "1",
    "EUR" : "1",
  },
  courseEng: {
    "UA" : "1",
    "RUB" : "1",
    "USD" : "1",
    "EUR" : "1",
  }
};

const reducer = {
  [courseActions.changeCourse]: (state, courseValue) => ({
    ...state,
    course: courseValue,
  }),
  [courseActions.changeCourseEng]: (state, courseValue) => ({
    ...state,
    courseEng: courseValue,
  }),
};

export default createReducer(reducer, initialState);
