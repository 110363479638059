import React from "react";
import { NavLink, Link } from "react-router-dom";
import { changeLanguage } from "../../../modules/language/language.actions";
import "./Header.css";
import { connect } from "react-redux";

const Header = (props) => {

  const { courseEng } = props;

  const changeLanguage = () => {
    props.changeLanguage('ru');
  }

  return (
    <div className="header">
      <div className="container">
        <h1>Fuel consumption calculation </h1>
        <nav className="navbar navbar-dark bg-dark mb-5 flex-row">
          <div className="menuHeader">
            <NavLink exact replace className="menuHeader_item" to="/eng/">
              Main
            </NavLink>
            <NavLink className="menuHeader_item" to="/eng/mpgtoliters">
              MPG to Litres
            </NavLink>
            <NavLink className="menuHeader_item" to="/eng/celsius-fahrenheit">
              °C to °F
            </NavLink>
            <NavLink className="menuHeader_item" to="/eng/history">
              History
            </NavLink>
            <Link className="menuHeader_item" to="/" onClick={changeLanguage}>
              Русский
            </Link>
          </div>
          <ul className="navbar-nav flex-column ml-md-auto d-none d-md-flex course-block">
          <li className="nav-item" key={0} ><b>Course:</b></li>
            {
              Object.keys(courseEng).map(function(key) {
                  if(key !== "USD"){
                    return (<li className="nav-item" key={key}><b>{key}:</b>{courseEng[key]}</li>);
                  }
                  
              } )
            }

        
      </ul>
        </nav>
      </div>
    </div>
  );
};

function mapStateToProps({ course }) {
  return {
    courseEng: course.courseEng,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (state) => dispatch(changeLanguage(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);