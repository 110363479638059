import { createReducer } from "redux-act";
import * as languageActions from "./language.actions";

//const lg = (window.location.pathname).split('/').slice(1,-1)[0];
const lg = (window.location.hash).split('/').slice(1,-1)[0];

const initialState = {
  language: lg === 'eng' ? 'eng' : 'ru', //eng
};

const reducer = {
  [languageActions.changeLanguage]: (state, language) => ({
    ...state,
    language: language,
  }),
};

export default createReducer(reducer, initialState);
