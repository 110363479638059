import React from "react";
import { Field } from "formik";
import { ratioFuelGasArr } from "../../../constants";
import { FilterCheckbox, Error, NumberInput } from "./FormikElements";
import vsbig from "../../../assets/images/vs-big.png";

const Step2 = (props) => {
  const { filters } = props.parentState.values;

  const filtersEng = ["Petrol", "Gas", "Gas/Petrol"];

  return (
    <>
      <div className="form-group">
        <p>
          In the second step, you need to select the average fuel consumption
          car and the cost of 1 liter of fuel.
        </p>

        <div className="filterSection">
          {["Бензин", "Газ", "Газ/Бензин"].map((val,i) => {
            return (
              <div className="form-check" key={val}>
                <img
                  src={vsbig}
                  alt={"car"}
                  className={
                    val === "Бензин" &&
                    filters.includes("Газ") &&
                    filters.includes("Бензин")
                      ? "icon"
                      : "icon not-active"
                  }
                />
                <Field
                  className="form-control"
                  component={FilterCheckbox}
                  name="filters"
                  value={val}
                  label={filtersEng[i]}
                />
              </div>
            );
          })}
          <Error name={"filters"} />
        </div>

        <div
          className={`${
            filters.includes("Бензин") ? "show" : "hide"
          } fuelSection`}
        >
          <div className="flex-block">
            <Field
              component={NumberInput}
              name={"fuelСonsumption"}
              placeholder={"Average gas mileage..."}
              label={"Average gas mileage"}
              className={"form-control"}
            />

          <Field name={"fuelType"} as="select" className={"form-control"}>
            <option value="liters">liters</option>
            <option value="gallons">gallons</option>
          </Field>

          </div>
          <div className="flex-block">
          <Field
            label={"The cost of 1 liter of gasoline"}
            component={NumberInput}
            name={"fuelPrice"}
            placeholder={"Gasoline cost..."}
            className={"form-control"}
          />
          <Field name={"fuelPriceCurrency"} as="select" className={"form-control"}>
              <option value="USD">USD</option>
              <option value="UA">UA</option>
              <option value="RUB">RUB</option>
              <option value="EUR">EUR</option>
            </Field>
            </div>
        </div>

        <div
          className={`${filters.includes("Газ") ? "show" : "hide"} gasSection`}
        >
          <div className="flex-block">
            <Field
              label={"Average gas consumption"}
              component={NumberInput}
              name={"gasСonsumption"}
              placeholder={"Average gas consumption..."}
              className={"form-control"}
            />

            <Field name={"gasfuelType"} as="select" className={"form-control"}>
              <option value="liters">liters</option>
              <option value="gallons">gallons</option>
            </Field>
          </div>

          <div className="flex-block">

            <Field
              label={"Cost of 1 liter of gas"}
              component={NumberInput}
              name={"gasPrice"}
              placeholder={"Gas cost..."}
              className={"form-control"}
            />

            <Field name={"gasPriceCurrency"} as="select" className={"form-control"}>
              <option value="USD">USD</option>
              <option value="UA">UA</option>
              <option value="RUB">RUB</option>
              <option value="EUR">EUR</option>
            </Field>
          </div>
        </div>

        <div
          className={`${
            filters.includes("Газ/Бензин") ? "show" : "hide"
          } fuelGasSection`}
        >
          <label htmlFor="ratioFuelGas">Gas / petrol ratio</label>
          <Field
            name={"ratioFuelGas"}
            component={"select"}
            className={"form-control"}
          >
            {ratioFuelGasArr.map((item) => {
              return (
                <option value={item} key={item}>
                  {item}
                </option>
              );
            })}
          </Field>


          <div className="flex-block">
            <Field
              label={"Average fuel consumption per 100km"}
              component={NumberInput}
              name={"fuelGasСonsumption"}
              placeholder={"Average fuel consumption..."}
              className={"form-control"}
            />

            <Field name={"fuelType"} as="select" className={"form-control"}>
              <option value="liters">liters</option>
              <option value="gallons">gallons</option>
            </Field>
          </div>

          <div className="flex-block">

            <Field
              label={"The cost of 1 liter of gasoline"}
              component={NumberInput}
              name={"fuelPrice"}
              placeholder={"Gasoline cost..."}
              className={"form-control"}
            />
            <Field name={"fuelPriceCurrency"} as="select" className={"form-control"}>
              <option value="USD">USD</option>
              <option value="UA">UA</option>
              <option value="RUB">RUB</option>
              <option value="EUR">EUR</option>
            </Field>
          </div>

          <div className="flex-block">

            <Field
              label={"Cost of 1 liter of gas"}
              component={NumberInput}
              name={"gasPrice"}
              placeholder={"Gas cost..."}
              className={"form-control"}
            />
            <Field name={"gasPriceCurrency"} as="select" className={"form-control"}>
              <option value="USD">USD</option>
              <option value="UA">UA</option>
              <option value="RUB">RUB</option>
              <option value="EUR">EUR</option>
            </Field>
            </div>
        </div>
      </div>
    </>
  );
};

export default Step2;
