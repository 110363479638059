import React from "react";
import { NavLink, Link } from "react-router-dom";
import "./Header.css";
import { connect } from "react-redux";
import { changeLanguage } from "../../modules/language/language.actions";

const Header = (props) => {
  const { course } = props;
  const changeLanguage = () => {
    props.changeLanguage('eng');
  }
  return (
    <div className="header">
      <div className="container">
        <h1>Расчет расхода топлива </h1>
        <nav className="navbar navbar-dark bg-dark mb-5 flex-row">
          <div className="menuHeader">
            <NavLink exact replace className="menuHeader_item" to="/">
              Главная
            </NavLink>
            <NavLink className="menuHeader_item" to="/mpgtoliters">
             MPG в Литры
            </NavLink>
            <NavLink className="menuHeader_item" to="/celsius-fahrenheit">
              °C в °F
            </NavLink>
            <NavLink className="menuHeader_item" to="/history">
              История
            </NavLink>
            <Link className="menuHeader_item" to="/eng/" onClick={changeLanguage}>
              English
            </Link>
          </div>
          <ul className="navbar-nav flex-column ml-md-auto d-none d-md-flex course-block">
            <li className="nav-item" key={0}><b>Курс:</b></li>
            {
              Object.keys(course).map(function(key) {
                if(key !== "UA"){
                  return (<li className="nav-item" key={key}><b>{key}:</b>{course[key]}</li>);
                }
                
              } )
            }
          </ul>
        </nav>
      </div>
    </div>
  );
};

function mapStateToProps({ course }) {
  return {
    course: course.course,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (state) => dispatch(changeLanguage(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
