import React, { useState } from "react";
import { Grid, Card } from "@material-ui/core";
import "./CelsiusFahrenheitPage.css";


const CelsiusFahrenheitPage = () => {

  const [f, setF] = useState(0); // галлоны
  const [c, setC] = useState(0); // литры
  const [FtoC, setFtoC] = useState(0); // галлоны
  const [CtoF, setCtoF] = useState(0);

  const inputChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;

    if( name === "f" ) {
      setF(value);
      setFtoC( (value - 32) * 5/9 );  //tC = (tF – 32) x 5/9.
    }
    if ( name === "c" ) {
      setC(value);
      setCtoF( (value * 9/5) + 32 ); // tF = (tC × 9/5) + 32
    }
    
  };


  //Вывод компонента
  return (
    <div className="main-content">
      <Card className="container table-compare">
        <Grid container>

            <div className="text-center">
              <h3>Градус Фаренгейта °F в Цельсия °C </h3>
              <p>Онлайн калькулятор для перевода <b>градусов Цельсия в градусы по Фаренгейту</b> и обратно, может перевести градусы Цельсия в Кельвина и наоборот.
</p>
            </div>

            <div className="cform-flex-block">
              <div className="cform-container">

                <p><strong>°F в °C </strong></p>
                <div className="cform-block cform-ltog-from">
                  <div className="cform-block-enter">
                    <div className="cform-input-block">
                      <span className="cform-input-label"> °F </span>
                      <input name="f" className="cform-input-1" value={f} placeholder="Введите °F" type="text" onChange={inputChange}/> 
                    </div>

                  </div>

                  <div className="cform-block-result">
                    <span className="cform-block-result-label"> °C =</span> 
                    <span className="cform-block-result-val cform-block-result-val-conv"> {FtoC} </span>
                  </div>
                </div>

                <p><strong>°C в °F </strong></p>
                <div className="cform-block cform-gtol-from">
                  <div className="cform-block-enter">
                    <div className="cform-input-block">
                      <span className="cform-input-label"> °C </span> 
                      <input name="c" className="cform-input-1" value={c} placeholder="Введите °C" type="text" onChange={inputChange} />

                    </div>
                  </div>

                  <div className="cform-block-result">
                    <span className="cform-block-result-label"> °F = </span> 
                    <span className="cform-block-result-val cform-block-result-val-conv">{CtoF} </span>
                  </div>

                </div>
              </div>
              <div className="cform-container">
                <br/><br/>
                <p>Конвертер <b>способен выразить</b> градусы по Цельсию в градусах по Фаренгейту, Кельвину.</p>
                <p><b>Например</b>: температура воздуха <b>32°C</b> градуса по Цельсию равна <b>90°F</b> градусов по Фаренгейту.</p>
                <p><b>1 градус Цельсия = 34 градуса по Фаренгейту</b></p>
                <p>tC = (tF – 32) x 5/9</p>
                <p>tF = (tC × 9/5) + 32</p>
              </div>
            </div>
            

        </Grid>
      </Card>
    </div>
  );
};

export default CelsiusFahrenheitPage;
