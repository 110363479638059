import React from "react";
import { Field, FieldArray } from "formik";
import { typesEng, brandsEng } from "../../../constants";
import { RadioButton, TextInput } from "./FormikElements";

const Step1 = (props) => {
  const { type } = props.parentState.values;
  let val = (type === "Автобус") ? "Bus" : type;
  const values = { typesEng };

  return (
    <div className="form-group">
      <p>
        For your convenience, we have developed a convenient calculator that will allow
        calculate the exact fuel consumption of your vehicle in accordance with the current regulations of the Ministry of Transport. For this
        you just need to go through all the steps.
      </p>
      <p>In the first step, you need to select the type and make of the car.</p>
      <div className="calculator__content-item active">
        <Field
          component={TextInput}
          name={"brand"}
          placeholder={"Enter the brand ..."}
          label={"Make"}
          className={"form-control"}
        />

        <div className="calculator__content-row">
          <div className="calculator__content-group">
            <div className="calculator__content-group-header">
              <span>Type</span>
            </div>
          </div>
          <div className="calculator__content-group">
            <div className="calculator__content-group-header">
              <span>Make</span>
            </div>
          </div>
        </div>
        <div className="calculator__content-inner">
          <div className="calculator__content-row">
            <div className="calculator__content-group">
              <FieldArray name="types">
                <div>
                  {values.typesEng &&
                    values.typesEng.length > 0 &&
                    values.typesEng.map((typeName, index) => (
                      <div key={index}>
                        <Field
                          name="type"
                          id={typeName}
                          component={RadioButton}
                          label={typeName}
                          className="form-check-input invisible"
                        />
                      </div>
                    ))}
                </div>
              </FieldArray>
            </div>

            <div className="calculator__content-group">
              <div className="mCustomScrollBox">
                <div className="mCSB_container">
                  {brandsEng[val].map((item) => {
                    return (
                      <div key={item}>
                        <Field
                          name={"brand"}
                          id={item}
                          component={RadioButton}
                          label={item}
                          className={`form-check-input invisible`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
