//import logo from './logo.svg';
import "./App.css";
import React, { useEffect } from 'react';
import { Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import HistoryPage from "./components/HistoryPage/HistoryPage";
import MpgToLitersPage from "./components/MpgToLitersPage/MpgToLitersPage";
import MpgToLitersPageEng from "./components/English/MpgToLitersPage/MpgToLitersPage";
import CelsiusFahrenheitPage from "./components/CelsiusFahrenheitPage/CelsiusFahrenheitPage";
import CelsiusFahrenheitPageEng from "./components/English/CelsiusFahrenheitPage/CelsiusFahrenheitPage";


import HeaderEng from "./components/English/Header/Header";
import FooterEng from "./components/English/Footer/Footer";
import HomeEng from "./components/English/Home/Home";
import HistoryPageEng from "./components/English/HistoryPage/HistoryPage";

import { ToastContainer } from "react-toastify";
import { changeLanguage } from "./modules/language/language.actions";
import { changeCourse,changeCourseEng } from "./modules/course/course.actions";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";



function App(props) {
  console.log(props);

  useEffect(() => {
    // get exchange data
  const getData = async () => {
  const url = "https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json";
  try {
    const response = await fetch(url);
    const json = await response.json();
    //let answer = json["Valute"];
   // let answer = json["cc"];
   let filterJson = json.filter((item) => {
      if((item["cc"] === "USD") || (item["cc"] === "RUB") || (item["cc"] === "EUR") )return item;
    }, {});
    
    return filterJson.reduce((acc, item) => {
      acc[item["cc"]] = item["rate"];
       return acc;
    }, {"UA" : 1});
  } catch (error) {
    console.log(error);
  }
};

    const convertValue = async () => {
      try {
        let valute = await getData();
        props.changeCourse(valute);
        let valuteEng = {...valute};
        Object.keys(valuteEng).map(function(key) {
          if(key === "USD"){ valuteEng[key] = 1;}
          if(key === "UA"){ valuteEng[key] = Number((1 / valute["USD"]).toFixed(4));}
          if(key === "EUR"){ valuteEng[key] = Number(( valute["EUR"]/valute["USD"] ).toFixed(4));}
          if(key === "RUB"){ valuteEng[key] = Number(( valute["RUB"]/valute["USD"] ).toFixed(4));}
          
      } )
        props.changeCourseEng(valuteEng);
    
      } catch (error) {
        console.log(error);
      }
    };

    convertValue();
  }, []);

  return (
    <div className="App">
      {
      (props.language === 'eng') ?
      <HeaderEng /> :
      <Header />
      }
      <ToastContainer />
      <Switch>
        <Route exact path={"/eng"} component={HomeEng} />
        <Route path={"/eng/edit/:id"} component={HomeEng} />
        <Route path={"/eng/history"} component={HistoryPageEng} />
        <Route exact path={"/"} component={Home} />
        <Route path={"/edit/:id"} component={Home} />
        <Route path={"/history"} component={HistoryPage} />
        <Route path={"/mpgtoliters"} component={MpgToLitersPage} />
        <Route path={"/eng/mpgtoliters"} component={MpgToLitersPageEng} />
        <Route path={"/celsius-fahrenheit"} component={CelsiusFahrenheitPage} />
        <Route path={"/eng/celsius-fahrenheit"} component={CelsiusFahrenheitPageEng} />
      </Switch>
      {
      (props.language === 'eng') ?
      <FooterEng /> :
      <Footer />
      }
 
    </div>
  );
}

function mapStateToProps({ language, course }) {
  return {
    language: language.language,
    course: course.course,
    course: course.courseEng,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (state) => dispatch(changeLanguage(state)),
    changeCourse: (state) => dispatch(changeCourse(state)),
    changeCourseEng: (state) => dispatch(changeCourseEng(state)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
