import React, { useState } from "react";
import { Grid, Card } from "@material-ui/core";
import "./MpgToLitersPage.css";


const MpgToLitersPage = () => {

  const [gallons, setGallons] = useState(0); // галлоны
  const [liters, setLiters] = useState(0); // литры
  const [gallonsToLiters, setGallonsToLiters] = useState(0); // галлоны
  const [litersToGallons, setLitersToGallons] = useState(0);

  const inputChange = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    console.log(event.target.name);
    if( name === "gallons" ) {
      setGallons(value);
      //setGallonsToLiters( (value * 3.785411784).toFixed(2) );
      setGallonsToLiters( (235.22 / value).toFixed(2) );  
    }
    if ( name === "liters" ) {
      setLiters(value);
      //setLitersToGallons( (value / 3.785411784).toFixed(2) );
      setLitersToGallons( (235.22 / value).toFixed(2) );
    }
    
  };


  //Вывод компонента
  return (
    <div className="main-content">
      <Card className="container table-compare">
        <Grid container>

            <div className="text-center">
              <h3>Перевод галлонов в литры(на 100 км) и обратно</h3>
              <p>С помощью данного бесплатного онлайн калькулятора вы сможете узнать значения объемов в разных единицах измерения. Калькулятор позволяет перевести галлоны в литры и наоборот. Преимуществом сервиса является то, что перевод (конвертация) осуществляется автоматически. Просто вводите значения в соответствующие поля.
              </p>
            </div>

            <div className="cform-flex-block">
              <div className="cform-container">

                <p><strong>Галлоны в литры ( на 100 км ) </strong></p>
                <div className="cform-block cform-gtol-from">
                  <div className="cform-block-enter">
                    <div className="cform-input-block">
                      <span className="cform-input-label">Галлоны</span> 
                      <input name="gallons" className="cform-input-1" value={gallons} placeholder="Введите галлоны" type="text" onChange={inputChange} />

                    </div>
                  </div>

                  <div className="cform-block-result">
                    <span className="cform-block-result-label"> Литры </span> 
                    <span className="cform-block-result-val cform-block-result-val-conv">{gallonsToLiters} </span>
                  </div>

                </div>

                <p><strong>Литры ( на 100 км ) в галлоны </strong></p>
                <div className="cform-block cform-ltog-from">
                  <div className="cform-block-enter">
                    <div className="cform-input-block">
                      <span className="cform-input-label">Литры</span>
                      <input name="liters" className="cform-input-1" value={liters} placeholder="Введите литры" type="text" onChange={inputChange}/> 
                    </div>

                  </div>

                  <div className="cform-block-result">
                    <span className="cform-block-result-label"> Галлоны </span> 
                    <span className="cform-block-result-val cform-block-result-val-conv"> {litersToGallons} </span>
                  </div>
                </div>
              </div>
              <div className="cform-container">
                <p><strong>Как перевести галлоны в литры и обратно</strong></p>
                <p>Чтобы конвертировать значения достаточно воспользоваться следующими формулами перевода. Но, учтите, что в зависимости от ситуации соотношения могут быть разными. Калькулятор же берет за основу часто используемую величину.</p>
                <p>1. Формула перевода галлонов в литры</p>
                <p><strong>ЛИТРЫ = GAL * 3,785411784</strong></p>
                <p>2. Формула перевода литров в галлоны</p>
                <p><strong>GAL = ЛИТРЫ / 3,785411784</strong></p>
                <p>Теперь, у вас всегда есть под рукой удобный и легкий калькулятор для конвертирования размеров.</p>
              </div>
            </div>
            

        </Grid>
      </Card>
    </div>
  );
};

export default MpgToLitersPage;
